@import 'variables';
@import 'breakpoints';
@import 'spacings';
.header-slide {
  width: 100%;
  height: auto;
  > img {
    width: 100%;
    height: auto;
  }
  .slide-content {
    @include padding-horizontal(100px);
    > div {
      width: 50%;
      h2 {
        font-size: 45px;
        line-height: 60px;
        @include media-breakpoint-down(xs) {
          font-size: 1em !important;
          line-height: 23px !important;
        }
      }
    }
    @include media-breakpoint-down(md) {
      @include padding-horizontal(50px);
      > div {
        width: 100%;
        h2 {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }
  }
}

.who-we-are {
  background: url('https://res.cloudinary.com/bahranda/image/upload/v1598105437/Bahranda%20Assets/farm-field_mr6rj5.png') no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  // height: 550px;
  width: 100%;
  .content {
    background: $dark-opacity;
    .images {
      flex: .3;
      flex-basis: 400px;
      max-width: 400px;
      min-width: 200px;
      img {
        width: 100%;
        &:nth-child(1) {transform: translateY(-10%)};
        &:nth-child(2) {transform: translateY(-10%)}
      }
      @include media-breakpoint-only(md) {
        flex-direction: row;
        flex-basis: 100%;
        max-width: 100%;
        img {
          width: 50%;
        }
      };
    }
    .about {
      flex: .7;
    };
    @include media-breakpoint-down(md) { 
      flex-direction: column;
      .about {
        flex: 0;
        flex-basis: 100%;
      }
    };
  }
  @include media-breakpoint-down(md) {
    // height: 920px;
    // .content {
    //   .images, .about { flex: .5 }
    // }
  }
}

// What-we-do section
.What-we-do {
  .flex-container {
    .sub-div {
      flex: 1;
      flex-basis: 336px;
      max-width: 336px;
      height: 430px;
      border: 1px solid rgba(6, 152, 1, 0.57);
      box-shadow: 0px 5px 10px rgba(6, 152, 1, 0.29);
      @include media-breakpoint-down(xs) {
        margin-right: 0;
        max-width: 100%;
        height: auto;
      }
      div {
        img {
          max-width: 80px;
          height: auto;
        }
      } 
    }
  }
}
// End of What-we-do section

.portrait-commodity-card {
  max-width: 200px;
  min-width: 200px;
  box-shadow: 0px 1px 4px rgba(6, 152, 1, 0.57);
  &:hover {
    box-shadow: 2px 2px 6px rgba(6, 152, 1, 0.57),
                -2px -2px 6px rgba(6, 152, 1, 0.57);
  }
  .thumbnail {
    height: 200px;
    width: 100%;
    border: 1px solid rgba(6, 152, 1, 0.29);
    img {
      width: 100%;
      height: 100%;
    }
    .status {
      position: absolute;
      top: 0px;
      left: 0px;
      @include media-breakpoint-down(sm) {
        font-size: .67em;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    max-width: 150px;
    min-width: 150px;
    .thumbnail {
      height: 150px;
    }
  }
}

.team-card {
  max-width: 200px;
  min-width: 200px;
  .avatar {
    img { 
      width: 100%;
      height: auto;
     }
  }
}

@include media-breakpoint-down(xsm) {
  // .portrait-commodity-card, 
}