/*****Variables*****/
$dark: #000;
$dark-opacity: rgba(0, 0, 0, 0.448);
$table-dark: rgba(0, 0, 0, 0.432);
$white: #fff;
$bg-color2: #F5FFFE;
$color1: #069801;
$color1-opacity: rgba(6, 152, 1, 0.35);
$color1-opacity-2: rgba(6, 152, 1, 0.15);
$gray-color: #C4C4C4;
$gray-color2: rgba(196, 196, 196, 0.15);
$font-family-1: 'Montserrat', sans-serif;
$font-family-2: 'Pacifico', cursive;
$font-family-3: 'Roboto', sans-serif;
$font-family-4: 'Georgia';
$danger: #D90606;
$danger-opacity: rgba(255, 7, 7, 0.28);
$orange: #FF7A00;
$yellow: #F0B900;
$slim-border: 1px solid rgba(83, 84, 85, 0.219);
$slim-border-2: 1px solid  #C4C4C4;