
/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  width: 100%;
  background: transparent;
  // color: #444;
  padding: 18px;
  transition: background-color 0.6s ease;
  &:hover, &.active {
    background: white;
  }
}

/* Style the accordion chevron icon */
.chevron-icon {
  transition: transform 0.6s ease;
  &.active { transform: rotate(180deg) }
}

/* Style the accordion content panel. Note: hidden by default */
.accordion-content {
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}