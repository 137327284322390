@import 'breakpoints';
.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}
.font-xlg {
  font-size: 2em;
}

.font-lg {
  font-size: 1.5em;
}

.font-md {
  font-size: 1.1em;
}

.font-sm {
  font-size: .95em;
}

.font-xsm {
  font-size: .85em;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-style-normal {
  font-style: normal;
}

@include media-breakpoint-down(md) {
  .font-lg { font-size: 1em };
  .font-md {
    font-size: .95em;
  }

}