@import 'breakpoints';
@import 'variables';

@keyframes slide-from-right {
  from {
    opacity: 0;
    transform: translateX(90%);
  } to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slide-to-top {

}

.slide-from-right {
  animation: slide-from-right .7s ease-out 0s 1;
  // transition: animation .5s ease-in-out 1s;
}

.fade-enter, .fade-appear {
  opacity: 0;
}
.fade-enter-active, .fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.fade-exit, .fade-appear-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}