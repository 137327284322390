@import 'variables';

.danger-text {
  color: $danger;
}

.bg-color1 {
  background: $color1;
}

.bg-color1-opacity {
  background: $color1-opacity
}

.bg-color1-opacity-2 {
  background: $color1-opacity-2
}

.bg-dark {
  background: $dark;
}

.bg-dark-opacity {
  background: $dark-opacity;
}
.bg-danger {
  background: $danger;
}

.bg-danger-opacity {
  background: $danger-opacity;
}

.bg-white {
  background: #fff;
}

.bg-gray {
  background: $gray-color2;
}

.bg-color2 {
  background: $bg-color2
}

.bg-yellow {
  background: $yellow
}

.color-gray {
  color: $gray-color;
}

.color-yellow {
  color: $yellow
}

.color-gray2 {
  color: $gray-color2;
}

.color-dark {
  color: #000;
}

.color-white {
  color: #ffff;
}

.color1 {
  color: $color1;
}

.trans-bg {
  background: #6a048346;
}