@import 'variables';
@import 'breakpoints';

.auth-container {
  background: url('https://res.cloudinary.com/bahranda/image/upload/v1598105486/Bahranda%20Assets/lady_invyfb.png') no-repeat center;
  background-size: cover;
  // @include media-breakpoint-up(lg) {  background-size: cover; }
  width: 100%;
  height: auto;
  .auth-card {
    background: $white;
    max-width: 400px;
    .card-title {
      > * {
        background: $white;
        color: $dark;
        flex: 1;
        border-bottom:  2px solid $gray-color;
        &.active {
          color: $color1;
          border-bottom:  2px solid $color1;
        }
      }
    }
  }
}

.checkbox * {
  line-height: 1em;
}