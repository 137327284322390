@import 'variables';

* {
  outline: none;
  vertical-align: middle;
  background-repeat: no-repeat;
  transition: .5s all;
}
*, ::after, ::before, html, body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $font-family-1
}

body {
  background: $bg-color2;
}

a {
  text-decoration: none;
}

a, span, p {
  line-height: 29px;
  font-style: normal;
}

li {
  list-style: none;
}
input, textarea, button {
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

button {
  background: none;
  &::-moz-focus-inner { border: none; }
}

h1, h2, h3, h4 { line-height: 37px }

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

/* Style table headers and table data */
th, td {
  text-align: center;
  padding: 16px;
}

th:first-child, td:first-child {
  text-align: left;
}